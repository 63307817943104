.react-autosuggest__container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0px 5px 0px 5px;
}

.react-autosuggest__input {
    font-family: 'Oswald', sans-serif;
    width: 100%;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 0;
    font-family: 'Oswald', sans-serif;
    font-size: 0.8em;
}

.react-autosuggest__suggestions-container--open {
    border: 1px solid #aaa;
    background-color: #fff;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.suggestion-item {
    padding: 20px;
    cursor: pointer;
    color: var(--color-black);
}

.suggestion-item-hint {
    font-size: 0.8em;
    color: #808080;
}

.suggestion-item-highlighted {
    background-color: var(--color-blue);
    color: var(--color-white);
}
