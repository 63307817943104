:root {
    --color-yellow: #e8e8b8;
    --color-white: #e8e8e8;
    --color-lightgray: #c8c8c8;
    --color-black: #202026;
    --color-blue: #242438;
    --color-gray: #404040;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: 24px;
    font-weight: normal;
    font-kerning: normal;
    font-variant-numeric: oldstyle-nums;
    font-family: 'SourceSerifPro', serif;
    line-height: 1.45;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
