@font-face {
    font-family: 'SourceSerifPro';
    src: url('fonts/SourceSerifPro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSerifPro';
    src: url('fonts/SourceSerifPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
