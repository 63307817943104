@import url(https://fonts.googleapis.com/css?family=Anton|Noto+Sans|Oswald&display=swap);


.react-autosuggest__container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0px 5px 0px 5px;
}

.react-autosuggest__input {
    font-family: 'Oswald', sans-serif;
    width: 100%;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 0;
    font-family: 'Oswald', sans-serif;
    font-size: 0.8em;
}

.react-autosuggest__suggestions-container--open {
    border: 1px solid #aaa;
    background-color: #fff;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.suggestion-item {
    padding: 20px;
    cursor: pointer;
    color: var(--color-black);
}

.suggestion-item-hint {
    font-size: 0.8em;
    color: #808080;
}

.suggestion-item-highlighted {
    background-color: var(--color-blue);
    color: var(--color-white);
}

@font-face {
    font-family: 'SourceSerifPro';
    src: url(/static/media/SourceSerifPro-Bold.b925ad9f.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSerifPro';
    src: url(/static/media/SourceSerifPro-Regular.ecde2939.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

:root {
    --color-yellow: #e8e8b8;
    --color-white: #e8e8e8;
    --color-lightgray: #c8c8c8;
    --color-black: #202026;
    --color-blue: #242438;
    --color-gray: #404040;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: 24px;
    font-weight: normal;
    -webkit-font-feature-settings: "kern", "onum";
            font-feature-settings: "kern", "onum";
    -webkit-font-kerning: normal;
            font-kerning: normal;
    font-variant-numeric: oldstyle-nums;
    font-family: 'SourceSerifPro', serif;
    line-height: 1.45;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

